import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useStore, useSubMe, useSubPosses, useSubProfiles, useInviteURL, useSubInvites } from '../hooks'
import FlashScreen from './FlashScreen'
import { isMobileDevice } from '../lib/sharing'
import { getItem } from '../lib/asyncStorage'

import {
  VOTE_2020_CAMPAIGN,
  LOGIN_PATH,
  AUTH_ROUTES,
  DASHBOARD_PATH,
  INDEX_PATH,
  DESKTOP_PATH,
  UNIVERAL_ROUTES,
  USER_PHOTO,
} from '../constants/posse'

import Router from 'next/router'
import { logMsg } from '../lib/err'

import ErrorDialog from './ErrorDialog'

function Auth({ children, path }) {
  const [{ auth }] = useStore()
  // setPath includes query args in the new path
  const [isMember, setIsMember] = useState()
  const [query, setPath] = useInviteURL()

  const { isAuthing, isAuthed, initted, error } = auth
  useSubMe()
  useSubPosses(VOTE_2020_CAMPAIGN)
  useSubProfiles()
  useSubInvites()

  const isAuthRoute = AUTH_ROUTES.includes(path)
  const isUniversalRoute = UNIVERAL_ROUTES.includes(path)

  // redirect from  /desktop if on mobile, redirects to /desktop  on non-mobile
  useEffect(() => {
    // No redirect on universal route
    if (isUniversalRoute) return
    if (isMobileDevice && path === DESKTOP_PATH) {
      Router.push(INDEX_PATH)
    }
    if (!isMobileDevice) {
      let params = new URLSearchParams(document.location.search.substring(1))
      if (params.get('c')) {
        Router.push(`${DESKTOP_PATH}?c=${params.get('c')}`)
      } else if (params.get('r')) {
        Router.push(`${DESKTOP_PATH}?r=${params.get('r')}`)
      } else {
        Router.push(DESKTOP_PATH)
      }
      return
    }
  }, [isMobileDevice])

  useEffect(() => {
    const asyncHelper = async () => {
      if (!isMobileDevice) return
      // No redirect on universal route
      if (isUniversalRoute) return
      // if there's an auth error, skip redirect
      if (error) return
      // check if member
      const isMember = (await getItem('member')) || false
      setIsMember(isMember)

      if (isAuthing) return // still authing
      // IS AUTHED
      if (isAuthed && isAuthRoute) {
        return
      }
      if (isAuthed && !isAuthRoute) {
        //  check for unique situation where user is authed but still on /join
        const needsPhoto = await getItem('redirectPhotoUpload')
        if (needsPhoto) {
          // clear query params
          setPath(USER_PHOTO, true)
        } else {
          setPath(DASHBOARD_PATH, true) // redirect to /dashboard when authed and not on auth route
        }
        return
      }
      if (!isAuthed && isMember) {
        // IS NOT AUTHED
        logMsg('unauthed member redirected to login')
        setPath(LOGIN_PATH) // is member but not authed
      }
      if (!isAuthed && isAuthRoute) {
        setPath(INDEX_PATH) // not authed but wants to get to an auth route
      }
    }
    asyncHelper()
  }, [isAuthRoute, isAuthing, isAuthed, error])

  // only show the auth error modal if it's an auth route
  if (isAuthRoute && error) {
    return (
      <ErrorDialog
        title="Auth Error"
        message={
          "We've run into a problem with your phone's internet connection or web browser.  " +
          'Please first check that you have signal. If you do, then close and re-open your browser. ' +
          "If you don't know how, then turn off and on your phone."
        }
      />
    )
  }

  if (!initted) return <FlashScreen />

  if (path === DESKTOP_PATH) {
    return children
  }

  if (isUniversalRoute) {
    return children
  }

  if (isAuthing) {
    return <FlashScreen />
  }

  if (isAuthRoute && isAuthed) {
    return children
  }

  if (!isAuthRoute && !isAuthed) {
    return children
  }

  return <FlashScreen />
}

Auth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  path: PropTypes.string.isRequired,
}

export default Auth
